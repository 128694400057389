import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const Multipliers = () => {
  const [multipliers, setMultipliers] = useState({
    experienceMultiplier: '',
    loyaltyMultiplier: '',
    offshoreMultiplier: '',
    actingAsMultiplier: '',
    pillarMultiplier: '',
    remoteMultiplier: ''
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    fetchMultipliers();
  }, []);

  const fetchMultipliers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/settings');
      const data = response.data.data;
      setMultipliers({
        experienceMultiplier: data.experienceMultiplier || '',
        loyaltyMultiplier: data.loyaltyMultiplier || '',
        offshoreMultiplier: data.offshoreMultiplier || '',
        actingAsMultiplier: data.actingAsMultiplier || '',
        pillarMultiplier: data.pillarMultiplier || '',
        remoteMultiplier: data.remoteMultiplier || ''
      });
    } catch (error) {
      console.error('Error fetching multipliers:', error);
      showToast('Failed to fetch multipliers', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await api.put('/settings', multipliers);
      showToast('Multipliers saved successfully');
    } catch (error) {
      console.error('Error saving multipliers:', error);
      showToast('Failed to save multipliers', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setMultipliers(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const multiplierFields = [
    { key: 'experienceMultiplier', label: 'Experience Years Multiplier' },
    { key: 'loyaltyMultiplier', label: 'Years with Company Multiplier' },
    { key: 'offshoreMultiplier', label: 'Offshore Project Multiplier' },
    { key: 'actingAsMultiplier', label: 'Acting as Different Grade Multiplier' },
    { key: 'pillarMultiplier', label: 'Pillar Multiplier' },
    { key: 'remoteMultiplier', label: 'Remote Work Multiplier' }
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Salary Multipliers</h1>
        <button
          onClick={handleSave}
          disabled={loading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
        >
          Save Changes
        </button>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {multiplierFields.map(field => (
            <div key={field.key}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {field.label}
              </label>
              <input
                type="number"
                value={multipliers[field.key]}
                onChange={(e) => handleChange(field.key, e.target.value)}
                className="w-full border rounded-md p-2"
                placeholder="Enter multiplier value"
                step="0.01"
                min="0"
              />
              <p className="mt-1 text-sm text-gray-500">
                {field.key === 'experienceMultiplier' && 'Applied per year of experience'}
                {field.key === 'loyaltyMultiplier' && 'Applied per year with the company'}
                {field.key === 'offshoreMultiplier' && 'Applied when working on offshore projects'}
                {field.key === 'actingAsMultiplier' && 'Applied when acting in a different grade'}
                {field.key === 'pillarMultiplier' && 'Applied for pillar employees'}
                {field.key === 'remoteMultiplier' && 'Applied for remote workers'}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded">
          <h3 className="text-lg font-medium mb-2">How Multipliers Work</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Experience Multiplier: Increases salary based on total years of experience</li>
            <li>Loyalty Multiplier: Additional increase based on years with the company</li>
            <li>Offshore Project Multiplier: Applied when employee works on offshore projects</li>
            <li>Acting As Multiplier: Applied when employee acts in a different grade</li>
            <li>Pillar Multiplier: Applied for employees designated as pillars</li>
            <li>Remote Multiplier: Applied for employees working remotely</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Multipliers;
