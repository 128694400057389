import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Debug CSS loading
const debugStyles = () => {
  const styles = document.styleSheets;
  console.log('Loaded stylesheets:', styles.length);
  for (let i = 0; i < styles.length; i++) {
    try {
      const rules = styles[i].cssRules || styles[i].rules;
      console.log(`Stylesheet ${i + 1}:`, {
        href: styles[i].href,
        ruleCount: rules?.length || 0
      });
    } catch (e) {
      console.log(`Stylesheet ${i + 1}: Unable to access rules (likely CORS)`);
    }
  }
};

// Debug environment
console.log('Environment:', {
  nodeEnv: process.env.NODE_ENV,
  apiUrl: process.env.REACT_APP_API_URL,
  debug: process.env.REACT_APP_DEBUG
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Debug styles after render
setTimeout(debugStyles, 1000);

// Report web vitals
reportWebVitals(console.log);
