import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const EmployeeForm = () => {
  const { id } = useParams();
  const isEditMode = !!id;

  const [formData, setFormData] = useState({
    name: '',
    technologyId: '',
    specializationId: '',
    gradeId: '',
    levelId: '',
    yearsExperience: '',
    yearsWithCompany: '',
    offshoreProject: false,
    actingAsDifferentGrade: false,
    pillar: false,
    remote: false
  });

  const [technologies, setTechnologies] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [grades, setGrades] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { showToast } = useToast();

  useEffect(() => {
    fetchData();
    if (isEditMode) {
      fetchEmployee();
    }
  }, [id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [techsRes, gradesRes, levelsRes] = await Promise.all([
        api.get('/technologies'),
        api.get('/grades'),
        api.get('/levels')
      ]);

      const techsData = techsRes?.data?.data || [];
      const gradesData = gradesRes?.data?.data || [];
      const levelsData = levelsRes?.data?.data || [];

      // Sort grades and levels by order
      const sortedGrades = gradesData.sort((a, b) => (a.order || 0) - (b.order || 0));
      const sortedLevels = levelsData.sort((a, b) => (a.order || 0) - (b.order || 0));

      setTechnologies(techsData);
      setGrades(sortedGrades);
      setLevels(sortedLevels);

      // If there's a technology selected, fetch its specializations
      if (formData.technologyId) {
        await fetchSpecializations(formData.technologyId);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast('Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployee = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/employees/${id}`);
      const employee = response.data.data;
      
      setFormData({
        name: employee.name,
        technologyId: employee.technologyId,
        specializationId: employee.specializationId,
        gradeId: employee.gradeId,
        levelId: employee.levelId,
        yearsExperience: employee.yearsExperience,
        yearsWithCompany: employee.yearsWithCompany,
        offshoreProject: employee.offshoreProject,
        actingAsDifferentGrade: employee.actingAsDifferentGrade,
        pillar: employee.pillar,
        remote: employee.remote
      });

      // Fetch specializations for the selected technology
      if (employee.technologyId) {
        await fetchSpecializations(employee.technologyId);
      }
    } catch (error) {
      console.error('Error fetching employee:', error);
      showToast('Failed to fetch employee', 'error');
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  };

  const fetchSpecializations = async (technologyId) => {
    if (!technologyId) {
      setSpecializations([]);
      return;
    }

    try {
      setLoading(true);
      const response = await api.get('/specializations', {
        params: { technologyId }
      });
      setSpecializations(response.data.data || []);
    } catch (error) {
      console.error('Error fetching specializations:', error);
      showToast('Failed to fetch specializations', 'error');
      setSpecializations([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTechnologyChange = async (e) => {
    const technologyId = e.target.value;
    setFormData(prev => ({ ...prev, technologyId, specializationId: '' }));
    await fetchSpecializations(technologyId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        ...formData,
        technologyId: Number(formData.technologyId),
        specializationId: Number(formData.specializationId),
        gradeId: Number(formData.gradeId),
        levelId: Number(formData.levelId),
        yearsExperience: Number(formData.yearsExperience),
        yearsWithCompany: Number(formData.yearsWithCompany)
      };

      if (isEditMode) {
        await api.put(`/employees/${id}`, payload);
        showToast('Employee updated successfully');
      } else {
        await api.post('/employees', payload);
        showToast('Employee created successfully');
      }
      navigate('/dashboard');
    } catch (error) {
      console.error('Error saving employee:', error);
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{isEditMode ? 'Edit Employee' : 'Add New Employee'}</h1>
      </div>

      <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Technology
            </label>
            <select
              value={formData.technologyId}
              onChange={handleTechnologyChange}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Technology</option>
              {technologies.map(tech => (
                <option key={tech.id} value={tech.id}>{tech.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Specialization
            </label>
            <select
              value={formData.specializationId}
              onChange={(e) => setFormData(prev => ({ ...prev, specializationId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
              disabled={!formData.technologyId || loading}
            >
              <option value="">Select Specialization</option>
              {specializations.map(spec => (
                <option key={spec.id} value={spec.id}>{spec.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Grade
            </label>
            <select
              value={formData.gradeId}
              onChange={(e) => setFormData(prev => ({ ...prev, gradeId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Grade</option>
              {grades.map(grade => (
                <option key={grade.id} value={grade.id}>{grade.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Level
            </label>
            <select
              value={formData.levelId}
              onChange={(e) => setFormData(prev => ({ ...prev, levelId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Level</option>
              {levels.map(level => (
                <option key={level.id} value={level.id}>{level.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Years of Experience
            </label>
            <input
              type="number"
              value={formData.yearsExperience}
              onChange={(e) => setFormData(prev => ({ ...prev, yearsExperience: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
              min="0"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Years with Company
            </label>
            <input
              type="number"
              value={formData.yearsWithCompany}
              onChange={(e) => setFormData(prev => ({ ...prev, yearsWithCompany: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
              min="0"
            />
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="offshoreProject"
              checked={formData.offshoreProject}
              onChange={(e) => setFormData(prev => ({ ...prev, offshoreProject: e.target.checked }))}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="offshoreProject" className="ml-2 block text-sm text-gray-900">
              Offshore Project
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="actingAsDifferentGrade"
              checked={formData.actingAsDifferentGrade}
              onChange={(e) => setFormData(prev => ({ ...prev, actingAsDifferentGrade: e.target.checked }))}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="actingAsDifferentGrade" className="ml-2 block text-sm text-gray-900">
              Acting as Different Grade
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="pillar"
              checked={formData.pillar}
              onChange={(e) => setFormData(prev => ({ ...prev, pillar: e.target.checked }))}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="pillar" className="ml-2 block text-sm text-gray-900">
              Pillar
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="remote"
              checked={formData.remote}
              onChange={(e) => setFormData(prev => ({ ...prev, remote: e.target.checked }))}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="remote" className="ml-2 block text-sm text-gray-900">
              Remote
            </label>
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => navigate('/dashboard')}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
          >
            {isEditMode ? 'Update' : 'Create'} Employee
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
