import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

const UserManagement = () => {
  const { user: currentUser, listUsers, createUser, updateUser, deleteUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    permissions: {
      showSettings: false,
      manageSettings: false,
      showSalary: false,
      showCost: false,
      manageEmployee: false,
      deleteEmployee: false
    }
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const data = await listUsers();
      setUsers(data);
    } catch (error) {
      toast.error('Failed to load users');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('perm_')) {
      const permName = name.replace('perm_', '');
      setFormData(prev => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [permName]: checked
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedUser) {
        await updateUser(selectedUser.id, {
          name: formData.name,
          permissions: formData.permissions
        });
        toast.success('User updated successfully');
      } else {
        await createUser({
          email: formData.email,
          name: formData.name,
          password: formData.password,
          permissions: formData.permissions
        });
        toast.success('User created successfully');
      }
      loadUsers();
      resetForm();
    } catch (error) {
      toast.error(error.response?.data?.message || 'Operation failed');
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        toast.success('User deleted successfully');
        loadUsers();
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to delete user');
      }
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setFormData({
      email: user.email,
      name: user.name,
      password: '',
      permissions: user.permissions
    });
  };

  const resetForm = () => {
    setSelectedUser(null);
    setFormData({
      email: '',
      name: '',
      password: '',
      permissions: {
        showSettings: false,
        manageSettings: false,
        showSalary: false,
        showCost: false,
        manageEmployee: false,
        deleteEmployee: false
      }
    });
  };

  if (!currentUser?.isAdmin) {
    return <div>Access denied</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">User Management</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">
            {selectedUser ? 'Edit User' : 'Create New User'}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {!selectedUser && (
              <>
                <div>
                  <label className="block text-sm font-medium mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required={!selectedUser}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required={!selectedUser}
                  />
                </div>
              </>
            )}
            <div>
              <label className="block text-sm font-medium mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium">Permissions</label>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_showSettings"
                    checked={formData.permissions.showSettings}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Show Settings
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_manageSettings"
                    checked={formData.permissions.manageSettings}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Edit/Delete Settings
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_showSalary"
                    checked={formData.permissions.showSalary}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Show Calculated Salary
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_showCost"
                    checked={formData.permissions.showCost}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Show Accumulated Cost
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_manageEmployee"
                    checked={formData.permissions.manageEmployee}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Edit Employee
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="perm_deleteEmployee"
                    checked={formData.permissions.deleteEmployee}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Delete Employee
                </label>
              </div>
            </div>

            <div className="flex space-x-2">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {selectedUser ? 'Update' : 'Create'}
              </button>
              {selectedUser && (
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4">Users</h2>
          <div className="space-y-4">
            {users.map(user => (
              <div key={user.id} className="border p-4 rounded">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">{user.name}</h3>
                    <p className="text-sm text-gray-600">{user.email}</p>
                    <div className="mt-2 text-sm">
                      <p>Permissions:</p>
                      <ul className="list-disc list-inside">
                        {Object.entries(user.permissions).map(([key, value]) => (
                          value && (
                            <li key={key} className="text-gray-600">
                              {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                            </li>
                          )
                        ))}
                      </ul>
                    </div>
                  </div>
                  {!user.isAdmin && (
                    <div className="space-x-2">
                      <button
                        onClick={() => handleEdit(user)}
                        className="text-blue-500 hover:text-blue-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="text-red-500 hover:text-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
