import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const BaseSalary = () => {
  const [baseSalaries, setBaseSalaries] = useState([]);
  const [grades, setGrades] = useState([]);
  const [levels, setLevels] = useState([]);
  const [editingBaseSalary, setEditingBaseSalary] = useState(null);
  const [formData, setFormData] = useState({
    gradeId: '',
    levelId: '',
    baseSalary: ''
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      console.log('Fetching data...');
      const [salariesRes, gradesRes, levelsRes] = await Promise.all([
        api.get('/base-salaries'),
        api.get('/grades'),
        api.get('/levels')
      ]);
      
      console.log('Responses:', { salariesRes, gradesRes, levelsRes });
      
      // Access the nested data structure correctly
      const gradesData = gradesRes?.data?.data || [];
      const levelsData = levelsRes?.data?.data || [];
      const salariesData = salariesRes?.data?.data || [];
      
      console.log('Processed data:', { gradesData, levelsData, salariesData });
      
      // Sort grades and levels by order if available
      const sortedGrades = gradesData.sort((a, b) => (a.order || 0) - (b.order || 0));
      const sortedLevels = levelsData.sort((a, b) => (a.order || 0) - (b.order || 0));
      
      setBaseSalaries(salariesData);
      setGrades(sortedGrades);
      setLevels(sortedLevels);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        console.error('Error response:', error.response);
      }
      showToast('Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        ...formData,
        gradeId: Number(formData.gradeId),
        levelId: Number(formData.levelId),
        baseSalary: Number(formData.baseSalary)
      };

      if (editingBaseSalary) {
        await api.put(`/base-salaries/${editingBaseSalary.id}`, payload);
        showToast('Base salary updated successfully');
      } else {
        await api.post('/base-salaries', payload);
        showToast('Base salary created successfully');
      }
      setFormData({ gradeId: '', levelId: '', baseSalary: '' });
      setEditingBaseSalary(null);
      fetchData();
    } catch (error) {
      console.error('Error saving base salary:', error);
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (salary) => {
    setEditingBaseSalary(salary);
    setFormData({
      gradeId: salary.gradeId,
      levelId: salary.levelId,
      baseSalary: salary.baseSalary
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this base salary?')) {
      try {
        setLoading(true);
        await api.delete(`/base-salaries/${id}`);
        showToast('Base salary deleted successfully');
        fetchData();
      } catch (error) {
        console.error('Error deleting base salary:', error);
        showToast('Failed to delete base salary', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const formatAmount = (amount) => {
    return `$ ${Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Base Salaries</h1>
      
      <form onSubmit={handleSubmit} className="mb-8 bg-white p-6 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Grade
            </label>
            <select
              value={formData.gradeId}
              onChange={(e) => setFormData(prev => ({ ...prev, gradeId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Grade</option>
              {grades.map(grade => (
                <option key={grade.id} value={grade.id}>{grade.name}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Level
            </label>
            <select
              value={formData.levelId}
              onChange={(e) => setFormData(prev => ({ ...prev, levelId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Level</option>
              {levels.map(level => (
                <option key={level.id} value={level.id}>{level.name}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Base Salary Amount
            </label>
            <div className="relative">
              <span className="absolute left-3 top-2 text-gray-500">$</span>
              <input
                type="number"
                value={formData.baseSalary}
                onChange={(e) => setFormData(prev => ({ ...prev, baseSalary: e.target.value }))}
                className="w-full border rounded-md p-2 pl-6"
                required
                placeholder="Enter amount"
                step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
        
        <div className="mt-4">
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            {editingBaseSalary ? 'Update' : 'Create'} Base Salary
          </button>
          {editingBaseSalary && (
            <button
              type="button"
              onClick={() => {
                setEditingBaseSalary(null);
                setFormData({ gradeId: '', levelId: '', baseSalary: '' });
              }}
              className="ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grade</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Level</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {baseSalaries.map(salary => (
              <tr key={salary.id}>
                <td className="px-6 py-4 whitespace-nowrap">{salary.grade?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{salary.level?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{formatAmount(salary.baseSalary)}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleEdit(salary)}
                    className="text-blue-600 hover:text-blue-900 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(salary.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BaseSalary;
