import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found in localStorage');
        setLoading(false);
        return;
      }

      console.log('Checking auth with token');
      const response = await authAPI.me();
      console.log('Auth check successful:', response.data);
      setUser(response.data.user);
    } catch (error) {
      console.error('Auth check error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      localStorage.removeItem('token');
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials) => {
    try {
      console.log('Login attempt with:', { email: credentials.email });
      const response = await authAPI.login(credentials);
      console.log('Login response:', {
        hasToken: !!response.token,
        hasUser: !!response.user
      });

      if (!response.token) {
        throw new Error('No token received in login response');
      }

      if (!response.user) {
        throw new Error('No user data received in login response');
      }

      localStorage.setItem('token', response.token);
      setUser(response.user);
      return response;
    } catch (error) {
      console.error('Login error in AuthContext:', {
        message: error.message,
        response: {
          status: error.response?.status,
          data: error.response?.data
        },
        config: {
          url: error.config?.url,
          baseURL: error.config?.baseURL
        }
      });
      throw error;
    }
  };

  const logout = () => {
    console.log('Logging out user');
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await authAPI.changePassword({
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      console.error('Change password error:', error);
      throw error;
    }
  };

  const createUser = async (userData) => {
    try {
      const response = await authAPI.createUser(userData);
      return response.data;
    } catch (error) {
      console.error('Create user error:', error);
      throw error;
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      const response = await authAPI.updateUser(userId, userData);
      return response.data;
    } catch (error) {
      console.error('Update user error:', error);
      throw error;
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await authAPI.deleteUser(userId);
      return response.data;
    } catch (error) {
      console.error('Delete user error:', error);
      throw error;
    }
  };

  const listUsers = async () => {
    try {
      const response = await authAPI.listUsers();
      return response.data.users;
    } catch (error) {
      console.error('List users error:', error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  const value = {
    user,
    login,
    logout,
    loading,
    changePassword,
    createUser,
    updateUser,
    deleteUser,
    listUsers
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
