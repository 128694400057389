// File: src/pages/settings/Grades.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash, ArrowUp, ArrowDown } from 'lucide-react';
import { useToast } from '../../contexts/ToastContext';
import { ConfirmationModal } from '../../components/common';
import api from '../../services/api';


const Grades = () => {
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    order: 0
  });
  const { showToast } = useToast();

  useEffect(() => {
    fetchGrades();
  }, []);

  const fetchGrades = async () => {
    try {
      setLoading(true);
      const response = await api.get('/grades');
      setGrades(response.data.data.sort((a, b) => a.order - b.order));
    } catch (error) {
      showToast('Failed to fetch grades', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editItem) {
        await api.put(`/grades/${editItem.id}`, formData);
        showToast('Grade updated successfully');
      } else {
        await api.post('/grades', formData);
        showToast('Grade created successfully');
      }
      setFormData({ name: '', order: 0 });
      setEditItem(null);
      setShowModal(false);
      fetchGrades();
    } catch (error) {
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    }
  };

  const handleMove = async (grade, direction) => {
    const currentIndex = grades.findIndex(g => g.id === grade.id);
    const newGrades = [...grades];
    
    if (direction === 'up' && currentIndex > 0) {
      [newGrades[currentIndex - 1].order, newGrades[currentIndex].order] = 
      [newGrades[currentIndex].order, newGrades[currentIndex - 1].order];
    } else if (direction === 'down' && currentIndex < grades.length - 1) {
      [newGrades[currentIndex].order, newGrades[currentIndex + 1].order] = 
      [newGrades[currentIndex + 1].order, newGrades[currentIndex].order];
    }

    try {
      await api.post('/grades/reorder', { 
        grades: newGrades.map(g => ({ id: g.id, order: g.order }))
      });
      fetchGrades();
    } catch (error) {
      showToast('Failed to reorder grades', 'error');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Grades</h2>
        <button
          onClick={() => {
            setEditItem(null);
            setFormData({ name: '', order: grades.length });
            setShowModal(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <Plus size={16} className="mr-2" />
          Add Grade
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {grades.map((grade, index) => (
              <tr key={grade.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">{grade.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{grade.order}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-3">
                    <button
                      onClick={() => handleMove(grade, 'up')}
                      disabled={index === 0}
                      className={`${index === 0 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-900'}`}
                    >
                      <ArrowUp size={16} />
                    </button>
                    <button
                      onClick={() => handleMove(grade, 'down')}
                      disabled={index === grades.length - 1}
                      className={`${index === grades.length - 1 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-900'}`}
                    >
                      <ArrowDown size={16} />
                    </button>
                    <button
                      onClick={() => {
                        setEditItem(grade);
                        setFormData({ name: grade.name, order: grade.order });
                        setShowModal(true);
                      }}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <Edit size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Add/Edit */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {editItem ? 'Edit Grade' : 'Add Grade'}
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  {editItem ? 'Update' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Grades;