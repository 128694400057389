// File: src/components/layout/Sidebar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const Sidebar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const baseNavItems = [
    { path: '/', label: 'Employees' },
    { path: '/settings', label: 'Settings' },
  ];

  // Add user management link for admins
  const navItems = user?.isAdmin 
    ? [...baseNavItems, { path: '/settings/users', label: 'User Management' }]
    : baseNavItems;

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <aside className="w-64 bg-white shadow-sm h-screen">
      <div className="flex items-center mt-5 px-5">
        <img src="/logo.svg" alt="Logo" className="h-8 w-auto mr-2" />
      </div>
      
      <div className="px-5 py-4">
        <span className="text-gray-700">
          Welcome, {user?.name || 'User'}
        </span>
      </div>

      <nav className="mt-2 px-2">
        {navItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `block px-4 py-2 rounded-md ${
                isActive
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-50'
              }`
            }
          >
            {item.label}
          </NavLink>
        ))}
        
        <button
          onClick={handleLogout}
          className="w-full text-left block px-4 py-2 rounded-md text-gray-600 hover:bg-gray-50"
        >
          Logout
        </button>
      </nav>
    </aside>
  );
};
