import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PermissionRoute = ({ children, requiredPermissions = [], requireAdmin = false }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check if admin is required
  if (requireAdmin && !user.isAdmin) {
    return <Navigate to="/" replace />;
  }

  // Check for specific permissions
  if (requiredPermissions.length > 0) {
    const hasRequiredPermissions = requiredPermissions.every(
      permission => user.permissions?.[permission]
    );

    if (!hasRequiredPermissions && !user.isAdmin) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

export default PermissionRoute;
