import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PermissionRoute from '../../components/auth/PermissionRoute';
import Technologies from './Technologies';
import Specializations from './Specializations';
import Grades from './Grades';
import Levels from './Levels';
import BaseSalary from './BaseSalary';
import AdjustmentFactors from './AdjustmentFactors';
import Benefits from './Benefits';
import Multipliers from './Multipliers';
import UserManagement from './UserManagement';

const Settings = () => {
  const { user } = useAuth();
  const canViewSettings = user?.permissions?.showSettings || user?.isAdmin;
  const canManageSettings = user?.permissions?.manageSettings || user?.isAdmin;

  const baseSettingsLinks = [
    { path: 'technologies', label: 'Technologies', permission: 'showSettings' },
    { path: 'specializations', label: 'Specializations', permission: 'showSettings' },
    { path: 'grades', label: 'Grades', permission: 'showSettings' },
    { path: 'levels', label: 'Levels', permission: 'showSettings' },
    { path: 'base-salary', label: 'Base Salary', permission: 'showSettings' },
    { path: 'adjustment-factors', label: 'Adjustment Factors', permission: 'showSettings' },
    { path: 'benefits', label: 'Benefits', permission: 'showSettings' },
    { path: 'multipliers', label: 'Salary Multipliers', permission: 'showSettings' }
  ];

  // Add user management link for admins
  const settingsLinks = user?.isAdmin 
    ? [...baseSettingsLinks, { path: 'users', label: 'User Management', adminOnly: true }]
    : baseSettingsLinks;

  // Filter links based on permissions
  const visibleLinks = settingsLinks.filter(link => {
    if (link.adminOnly) return user?.isAdmin;
    if (link.permission) return user?.permissions?.[link.permission] || user?.isAdmin;
    return true;
  });

  if (!canViewSettings && !user?.isAdmin) {
    return <div className="p-6">Access denied</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {visibleLinks.map(link => (
          <Link
            key={link.path}
            to={link.path}
            className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200 text-center"
          >
            <h2 className="text-lg font-medium text-gray-900">{link.label}</h2>
          </Link>
        ))}
      </div>

      <div className="bg-white rounded-lg shadow">
        <Routes>
          <Route
            path="technologies"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Technologies />
              </PermissionRoute>
            }
          />
          <Route
            path="specializations"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Specializations />
              </PermissionRoute>
            }
          />
          <Route
            path="grades"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Grades />
              </PermissionRoute>
            }
          />
          <Route
            path="levels"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Levels />
              </PermissionRoute>
            }
          />
          <Route
            path="base-salary"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <BaseSalary />
              </PermissionRoute>
            }
          />
          <Route
            path="adjustment-factors"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <AdjustmentFactors />
              </PermissionRoute>
            }
          />
          <Route
            path="benefits"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Benefits />
              </PermissionRoute>
            }
          />
          <Route
            path="multipliers"
            element={
              <PermissionRoute requiredPermissions={['showSettings']}>
                <Multipliers />
              </PermissionRoute>
            }
          />
          <Route
            path="users"
            element={
              <PermissionRoute requireAdmin={true}>
                <UserManagement />
              </PermissionRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
