import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { employeesAPI, technologiesAPI, specializationsAPI, gradesAPI, levelsAPI } from '../../services/api';
import { useToast } from '../../contexts/ToastContext';
import { useAuth } from '../../contexts/AuthContext';
import ChangePasswordModal from '../../components/auth/ChangePasswordModal';

const Dashboard = () => {
  const { user } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [grades, setGrades] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [searchName, setSearchName] = useState('');
  const [filters, setFilters] = useState({
    technologyId: '',
    specializationId: '',
    gradeId: '',
    levelId: '',
    salaryFrom: '',
    salaryTo: ''
  });

  const navigate = useNavigate();
  const { showToast } = useToast();

  const canManageEmployee = user?.permissions?.manageEmployee || user?.isAdmin;
  const canDeleteEmployee = user?.permissions?.deleteEmployee || user?.isAdmin;
  const canViewSalary = user?.permissions?.showSalary || user?.isAdmin;
  const canViewCost = user?.permissions?.showCost || user?.isAdmin;

  useEffect(() => {
    fetchData();
    fetchEmployees();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [techsRes, gradesRes, levelsRes] = await Promise.all([
        technologiesAPI.list(),
        gradesAPI.list(),
        levelsAPI.list()
      ]);

      const techsData = techsRes?.data?.data || [];
      const gradesData = gradesRes?.data?.data || [];
      const levelsData = levelsRes?.data?.data || [];

      // Sort grades and levels by order
      const sortedGrades = gradesData.sort((a, b) => (a.order || 0) - (b.order || 0));
      const sortedLevels = levelsData.sort((a, b) => (a.order || 0) - (b.order || 0));

      setTechnologies(techsData);
      setGrades(sortedGrades);
      setLevels(sortedLevels);
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast('Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchSpecializations = async (technologyId) => {
    if (!technologyId) {
      setSpecializations([]);
      return;
    }

    try {
      setLoading(true);
      const response = await specializationsAPI.list(technologyId);
      setSpecializations(response.data.data || []);
    } catch (error) {
      console.error('Error fetching specializations:', error);
      showToast('Failed to fetch specializations', 'error');
      setSpecializations([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      // Convert filter values to appropriate types
      const processedFilters = {
        technologyId: filters.technologyId ? Number(filters.technologyId) : undefined,
        specializationId: filters.specializationId ? Number(filters.specializationId) : undefined,
        gradeId: filters.gradeId ? Number(filters.gradeId) : undefined,
        levelId: filters.levelId ? Number(filters.levelId) : undefined,
        salaryFrom: filters.salaryFrom ? Number(filters.salaryFrom) : undefined,
        salaryTo: filters.salaryTo ? Number(filters.salaryTo) : undefined
      };

      // Remove undefined values
      const cleanFilters = Object.fromEntries(
        Object.entries(processedFilters).filter(([_, value]) => value !== undefined)
      );

      const response = await employeesAPI.list(cleanFilters);
      setEmployees(response.data.data || []);
    } catch (error) {
      console.error('Error fetching employees:', error);
      showToast('Failed to fetch employees', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTechnologyChange = async (e) => {
    const technologyId = e.target.value;
    setFilters(prev => ({ ...prev, technologyId, specializationId: '' }));
    await fetchSpecializations(technologyId);
  };

  const handleImport = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      await employeesAPI.import(file);
      showToast('Employees imported successfully');
      fetchEmployees();
    } catch (error) {
      console.error('Error importing employees:', error);
      showToast(error.response?.data?.message || 'Failed to import employees', 'error');
    } finally {
      setLoading(false);
      // Reset file input
      e.target.value = '';
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const response = await employeesAPI.downloadTemplate();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'employees_template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading template:', error);
      showToast('Failed to download template', 'error');
    }
  };

  const handleExport = async () => {
    try {
      const response = await employeesAPI.export();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'employees_export.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting employees:', error);
      showToast('Failed to export employees', 'error');
    }
  };

  const handleDeleteEmployee = async (id) => {
    if (!window.confirm('Are you sure you want to delete this employee?')) {
      return;
    }

    try {
      setLoading(true);
      await employeesAPI.delete(id);
      showToast('Employee deleted successfully');
      fetchEmployees(); // Refresh the list
    } catch (error) {
      console.error('Error deleting employee:', error);
      showToast('Failed to delete employee', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    fetchEmployees();
  };

  const handleReset = () => {
    setFilters({
      technologyId: '',
      specializationId: '',
      gradeId: '',
      levelId: '',
      salaryFrom: '',
      salaryTo: ''
    });
    setSearchName('');
    setSpecializations([]);
    setSortConfig({ key: 'id', direction: 'asc' });
    fetchEmployees();
  };

  const formatCurrency = (amount) => {
    return `$ ${Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  const formatBoolean = (value) => value ? 'Yes' : 'No';

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const getSortedEmployees = () => {
    const filteredEmployees = employees.filter(employee => 
      employee.name.toLowerCase().includes(searchName.toLowerCase())
    );

    return [...filteredEmployees].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'yearsExperience' || sortConfig.key === 'yearsWithCompany') {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (sortConfig.key === 'calculatedSalary' || sortConfig.key === 'accumulatedCost') {
        aValue = Number(aValue) || 0;
        bValue = Number(bValue) || 0;
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return '↕️';
    return sortConfig.direction === 'asc' ? '↑' : '↓';
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Employees</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsPasswordModalOpen(true)}
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
          >
            Change Password
          </button>
          <button
            onClick={handleExport}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Export Excel
          </button>
          <button
            onClick={handleDownloadTemplate}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Download Template
          </button>
          {canManageEmployee && (
            <>
              <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Import Excel
                <input
                  type="file"
                  accept=".xlsx,.xls"
                  className="hidden"
                  onChange={handleImport}
                  disabled={loading}
                />
              </label>
              <button
                onClick={() => navigate('/employees/new')}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Add Employee
              </button>
            </>
          )}
        </div>
      </div>

      <form onSubmit={handleFilter} className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Search by Name
            </label>
            <input
              type="text"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="w-full border rounded-md p-2"
              placeholder="Enter employee name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Technology
            </label>
            <select
              value={filters.technologyId}
              onChange={handleTechnologyChange}
              className="w-full border rounded-md p-2"
            >
              <option value="">All Technologies</option>
              {technologies.map(tech => (
                <option key={tech.id} value={tech.id}>{tech.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Specialization
            </label>
            <select
              value={filters.specializationId}
              onChange={(e) => setFilters(prev => ({ ...prev, specializationId: e.target.value }))}
              className="w-full border rounded-md p-2"
              disabled={!filters.technologyId || loading}
            >
              <option value="">All Specializations</option>
              {specializations.map(spec => (
                <option key={spec.id} value={spec.id}>{spec.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Grade
            </label>
            <select
              value={filters.gradeId}
              onChange={(e) => setFilters(prev => ({ ...prev, gradeId: e.target.value }))}
              className="w-full border rounded-md p-2"
            >
              <option value="">All Grades</option>
              {grades.map(grade => (
                <option key={grade.id} value={grade.id}>{grade.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Level
            </label>
            <select
              value={filters.levelId}
              onChange={(e) => setFilters(prev => ({ ...prev, levelId: e.target.value }))}
              className="w-full border rounded-md p-2"
            >
              <option value="">All Levels</option>
              {levels.map(level => (
                <option key={level.id} value={level.id}>{level.name}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Salary From
            </label>
            <input
              type="number"
              value={filters.salaryFrom}
              onChange={(e) => setFilters(prev => ({ ...prev, salaryFrom: e.target.value }))}
              className="w-full border rounded-md p-2"
              placeholder="Min salary"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Salary To
            </label>
            <input
              type="number"
              value={filters.salaryTo}
              onChange={(e) => setFilters(prev => ({ ...prev, salaryTo: e.target.value }))}
              className="w-full border rounded-md p-2"
              placeholder="Max salary"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={handleReset}
            className="px-4 py-2 border rounded-md hover:bg-gray-50"
          >
            Reset
          </button>
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            Apply Filters
          </button>
        </div>
      </form>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  ID {getSortIcon('id')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Technology</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialization</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Level</th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('yearsExperience')}
                >
                  Experience {getSortIcon('yearsExperience')}
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('yearsWithCompany')}
                >
                  Company Years {getSortIcon('yearsWithCompany')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Offshore</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acting As</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pillar</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Remote</th>
                {canViewSalary && (
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('calculatedSalary')}
                  >
                    Calculated Salary {getSortIcon('calculatedSalary')}
                  </th>
                )}
                {canViewCost && (
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('accumulatedCost')}
                  >
                    Total Cost {getSortIcon('accumulatedCost')}
                  </th>
                )}
                {(canManageEmployee || canDeleteEmployee) && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {getSortedEmployees().map(employee => (
                <tr key={employee.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {canManageEmployee ? (
                      <span
                        className="text-blue-600 hover:text-blue-900 cursor-pointer"
                        onClick={() => navigate(`/employees/edit/${employee.id}`)}
                      >
                        {employee.id}
                      </span>
                    ) : (
                      employee.id
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {canManageEmployee ? (
                      <span
                        className="text-blue-600 hover:text-blue-900 cursor-pointer"
                        onClick={() => navigate(`/employees/edit/${employee.id}`)}
                      >
                        {employee.name}
                      </span>
                    ) : (
                      employee.name
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.technology?.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.specialization?.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.grade?.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.level?.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.yearsExperience} years</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.yearsWithCompany} years</td>
                  <td className="px-6 py-4 whitespace-nowrap">{formatBoolean(employee.offshoreProject)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{formatBoolean(employee.actingAsDifferentGrade)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{formatBoolean(employee.pillar)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{formatBoolean(employee.remote)}</td>
                  {canViewSalary && (
                    <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(employee.calculatedSalary)}</td>
                  )}
                  {canViewCost && (
                    <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(employee.accumulatedCost)}</td>
                  )}
                  {(canManageEmployee || canDeleteEmployee) && (
                    <td className="px-6 py-4 whitespace-nowrap">
                      {canDeleteEmployee && (
                        <button
                          onClick={() => handleDeleteEmployee(employee.id)}
                          className="text-red-600 hover:text-red-900"
                          disabled={loading}
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />
    </div>
  );
};

export default Dashboard;
