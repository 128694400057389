import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const AdjustmentFactors = () => {
  const [adjustmentFactors, setAdjustmentFactors] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [specializations, setSpecializations] = useState([]);
  const [editingFactor, setEditingFactor] = useState(null);
  const [formData, setFormData] = useState({
    technologyId: '',
    specializationId: '',
    factor: ''
  });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [factorsRes, techsRes] = await Promise.all([
        api.get('/adjustment-factors'),
        api.get('/technologies')
      ]);
      
      const factorsData = factorsRes?.data?.data || [];
      const techsData = techsRes?.data?.data || [];
      
      setAdjustmentFactors(factorsData);
      setTechnologies(techsData);

      // Load specializations for selected technology
      if (formData.technologyId) {
        const specsRes = await api.get('/specializations', {
          params: { technologyId: formData.technologyId }
        });
        setSpecializations(specsRes?.data?.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast('Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTechnologyChange = async (e) => {
    const technologyId = e.target.value;
    setFormData(prev => ({ ...prev, technologyId, specializationId: '' }));
    
    if (technologyId) {
      try {
        const response = await api.get('/specializations', {
          params: { technologyId }
        });
        setSpecializations(response?.data?.data || []);
      } catch (error) {
        console.error('Error fetching specializations:', error);
        showToast('Failed to fetch specializations', 'error');
      }
    } else {
      setSpecializations([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        ...formData,
        technologyId: Number(formData.technologyId),
        specializationId: Number(formData.specializationId),
        factor: Number(formData.factor)
      };

      if (editingFactor) {
        await api.put(`/adjustment-factors/${editingFactor.id}`, payload);
        showToast('Adjustment factor updated successfully');
      } else {
        await api.post('/adjustment-factors', payload);
        showToast('Adjustment factor created successfully');
      }
      setFormData({ technologyId: '', specializationId: '', factor: '' });
      setEditingFactor(null);
      fetchData();
    } catch (error) {
      console.error('Error saving adjustment factor:', error);
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (factor) => {
    setEditingFactor(factor);
    setFormData({
      technologyId: factor.technologyId,
      specializationId: factor.specializationId,
      factor: factor.factor
    });
    // Load specializations for the selected technology
    handleTechnologyChange({ target: { value: factor.technologyId } });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this adjustment factor?')) {
      try {
        setLoading(true);
        await api.delete(`/adjustment-factors/${id}`);
        showToast('Adjustment factor deleted successfully');
        fetchData();
      } catch (error) {
        console.error('Error deleting adjustment factor:', error);
        showToast('Failed to delete adjustment factor', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const formatFactor = (factor) => {
    const percentage = (Number(factor) * 100).toFixed(0);
    return `${percentage >= 0 ? '+' : ''}${percentage}%`;
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Adjustment Factors</h1>
      
      <form onSubmit={handleSubmit} className="mb-8 bg-white p-6 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Technology
            </label>
            <select
              value={formData.technologyId}
              onChange={handleTechnologyChange}
              className="w-full border rounded-md p-2"
              required
            >
              <option value="">Select Technology</option>
              {technologies.map(tech => (
                <option key={tech.id} value={tech.id}>{tech.name}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Specialization
            </label>
            <select
              value={formData.specializationId}
              onChange={(e) => setFormData(prev => ({ ...prev, specializationId: e.target.value }))}
              className="w-full border rounded-md p-2"
              required
              disabled={!formData.technologyId}
            >
              <option value="">Select Specialization</option>
              {specializations.map(spec => (
                <option key={spec.id} value={spec.id}>{spec.name}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Adjustment Factor
            </label>
            <div className="relative">
              <input
                type="number"
                value={formData.factor}
                onChange={(e) => setFormData(prev => ({ ...prev, factor: e.target.value }))}
                className="w-full border rounded-md p-2 pr-8"
                required
                placeholder="Enter factor (e.g., 0.2 for +20%, -0.1 for -10%)"
                step="0.01"
                min="-1"
                max="1"
              />
              <span className="absolute right-3 top-2 text-gray-500">%</span>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Values between -100% (-1.0) and +100% (1.0)
            </p>
          </div>
        </div>
        
        <div className="mt-4">
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            {editingFactor ? 'Update' : 'Create'} Adjustment Factor
          </button>
          {editingFactor && (
            <button
              type="button"
              onClick={() => {
                setEditingFactor(null);
                setFormData({ technologyId: '', specializationId: '', factor: '' });
              }}
              className="ml-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Technology</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialization</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Factor</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {adjustmentFactors.map(factor => (
              <tr key={factor.id}>
                <td className="px-6 py-4 whitespace-nowrap">{factor.technology?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{factor.specialization?.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{formatFactor(factor.factor)}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleEdit(factor)}
                    className="text-blue-600 hover:text-blue-900 mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(factor.id)}
                    className="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdjustmentFactors;
