// File: src/pages/settings/Levels.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash, ArrowUp, ArrowDown } from 'lucide-react';
import { useToast } from '../../contexts/ToastContext';
import { ConfirmationModal } from '../../components/common';
import api from '../../services/api';

const Levels = () => {
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    order: 0
  });
  const { showToast } = useToast();

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    try {
      setLoading(true);
      const response = await api.get('/levels');
      setLevels(response.data.data.sort((a, b) => a.order - b.order));
    } catch (error) {
      showToast('Failed to fetch levels', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editItem) {
        await api.put(`/levels/${editItem.id}`, formData);
        showToast('Level updated successfully');
      } else {
        await api.post('/levels', formData);
        showToast('Level created successfully');
      }
      setFormData({ name: '', order: 0 });
      setEditItem(null);
      setShowModal(false);
      fetchLevels();
    } catch (error) {
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    }
  };

  const handleMove = async (level, direction) => {
    const currentIndex = levels.findIndex(l => l.id === level.id);
    const newLevels = [...levels];
    
    if (direction === 'up' && currentIndex > 0) {
      [newLevels[currentIndex - 1].order, newLevels[currentIndex].order] = 
      [newLevels[currentIndex].order, newLevels[currentIndex - 1].order];
    } else if (direction === 'down' && currentIndex < levels.length - 1) {
      [newLevels[currentIndex].order, newLevels[currentIndex + 1].order] = 
      [newLevels[currentIndex + 1].order, newLevels[currentIndex].order];
    }

    try {
      await api.post('/levels/reorder', { 
        levels: newLevels.map(l => ({ id: l.id, order: l.order }))
      });
      fetchLevels();
    } catch (error) {
      showToast('Failed to reorder levels', 'error');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Levels</h2>
        <button
          onClick={() => {
            setEditItem(null);
            setFormData({ name: '', order: levels.length });
            setShowModal(true);
          }}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          <Plus size={16} className="mr-2" />
          Add Level
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Order
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {levels.map((level, index) => (
              <tr key={level.id} className="hover:bg-gray-50 transition-colors">
                <td className="px-6 py-4 whitespace-nowrap">{level.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{level.order}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-3">
                    <button
                      onClick={() => handleMove(level, 'up')}
                      disabled={index === 0}
                      className={`${
                        index === 0 
                          ? 'text-gray-400 cursor-not-allowed' 
                          : 'text-blue-600 hover:text-blue-900'
                      } transition-colors`}
                    >
                      <ArrowUp size={16} />
                    </button>
                    <button
                      onClick={() => handleMove(level, 'down')}
                      disabled={index === levels.length - 1}
                      className={`${
                        index === levels.length - 1 
                          ? 'text-gray-400 cursor-not-allowed' 
                          : 'text-blue-600 hover:text-blue-900'
                      } transition-colors`}
                    >
                      <ArrowDown size={16} />
                    </button>
                    <button
                      onClick={() => {
                        setEditItem(level);
                        setFormData({ name: level.name, order: level.order });
                        setShowModal(true);
                      }}
                      className="text-blue-600 hover:text-blue-900 transition-colors"
                    >
                      <Edit size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for Add/Edit */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {editItem ? 'Edit Level' : 'Add Level'}
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border rounded-md hover:bg-gray-50 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                >
                  {editItem ? 'Update' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Levels;