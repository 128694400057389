// File: src/pages/settings/Specializations.jsx
import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { useToast } from '../../contexts/ToastContext';
import { TableSkeleton } from '../../components/common';
import api from '../../services/api';

const Specializations = () => {
  const [specializations, setSpecializations] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTech, setSelectedTech] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    technology_id: ''
  });
  const { showToast } = useToast();

  useEffect(() => {
    fetchTechnologies();
  }, []);

  useEffect(() => {
    if (selectedTech) {
      fetchSpecializations(selectedTech);
    } else {
      setSpecializations([]);
    }
  }, [selectedTech]);

  const fetchTechnologies = async () => {
    try {
      const response = await api.get('/technologies');
      setTechnologies(response.data.data);
      if (response.data.data.length > 0) {
        setSelectedTech(response.data.data[0].id.toString());
      }
    } catch (error) {
      showToast('Failed to fetch technologies', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchSpecializations = async (techId) => {
    try {
      setLoading(true);
      const response = await api.get(`/specializations?technology_id=${techId}`);
      setSpecializations(response.data.data);
    } catch (error) {
      showToast('Failed to fetch specializations', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        technologyId: parseInt(formData.technology_id)
      };

      if (editItem) {
        await api.put(`/specializations/${editItem.id}`, payload);
        showToast('Specialization updated successfully');
      } else {
        await api.post('/specializations', payload);
        showToast('Specialization created successfully');
      }
      setFormData({ name: '', technology_id: selectedTech });
      setEditItem(null);
      setShowModal(false);
      fetchSpecializations(selectedTech);
    } catch (error) {
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    }
  };

  const handleDelete = async (spec) => {
    if (window.confirm('Are you sure you want to delete this specialization?')) {
      try {
        await api.delete(`/specializations/${spec.id}`);
        showToast('Specialization deleted successfully');
        fetchSpecializations(selectedTech);
      } catch (error) {
        showToast('Failed to delete specialization', 'error');
      }
    }
  };

  if (!technologies.length) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="text-center py-12">
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Technologies Found</h3>
          <p className="text-gray-500 mb-4">Please add technologies before managing specializations.</p>
          <button
            onClick={() => window.location.href = '/settings'} 
            className="text-blue-600 hover:text-blue-800"
          >
            Go to Technologies
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">Specializations</h2>
        <div className="flex items-center space-x-4">
          <select
            value={selectedTech}
            onChange={(e) => setSelectedTech(e.target.value)}
            className="border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {technologies.map(tech => (
              <option key={tech.id} value={tech.id}>{tech.name}</option>
            ))}
          </select>
          <button
            onClick={() => {
              setEditItem(null);
              setFormData({ name: '', technology_id: selectedTech });
              setShowModal(true);
            }}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus size={16} className="mr-2" />
            Add Specialization
          </button>
        </div>
      </div>

      {loading ? (
        <TableSkeleton />
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Technology
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {specializations.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-6 py-4 text-center text-gray-500">
                    No specializations found for this technology
                  </td>
                </tr>
              ) : (
                specializations.map((spec) => (
                  <tr key={spec.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">{spec.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {technologies.find(t => t.id === spec.technologyId)?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => {
                            setEditItem(spec);
                            setFormData({
                              name: spec.name,
                              technology_id: spec.technologyId.toString()
                            });
                            setShowModal(true);
                          }}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <Edit size={16} />
                        </button>
                        <button
                          onClick={() => handleDelete(spec)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash size={16} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {editItem ? 'Edit Specialization' : 'Add Specialization'}
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Technology
                </label>
                <select
                  value={formData.technology_id}
                  onChange={(e) => setFormData({ ...formData, technology_id: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select Technology</option>
                  {technologies.map(tech => (
                    <option key={tech.id} value={tech.id}>{tech.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  {editItem ? 'Update' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Specializations;