import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastProvider } from './contexts/ToastContext';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/auth/Login';
import Layout from './components/layout/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import EmployeeForm from './pages/employees/EmployeeForm';
import ProtectedRoute from './components/auth/ProtectedRoute';
import PermissionRoute from './components/auth/PermissionRoute';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ToastProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings/*" element={<Settings />} />
              <Route 
                path="/employees/new" 
                element={
                  <PermissionRoute requiredPermissions={['manageEmployee']}>
                    <EmployeeForm />
                  </PermissionRoute>
                } 
              />
              <Route 
                path="/employees/edit/:id" 
                element={
                  <PermissionRoute requiredPermissions={['manageEmployee']}>
                    <EmployeeForm />
                  </PermissionRoute>
                } 
              />
            </Route>
          </Routes>
        </ToastProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
